import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import * as styles from '../styles/footer.module.scss'
import About from './about'
import FooterPageContents from './footerpagecontents'

const Footer = () =>{
    return(
        <footer>
            <div className = {styles.footerwrapper}>
                <div className = {styles.footerbackgroundimg}>
                    <StaticImage
                        src = "../images/footer.png"
                        alt = "footer image"
                        placeholder="none"
                        
                    />
                </div>

                <div className = {styles.flexfooter}>
                    <div className = {styles.footerabout}>
                        <About isVertical={true}></About>
                    </div>

                    <div className = {styles.footerpagecontentswrapper}>
                        <FooterPageContents></FooterPageContents>
                    </div>
                </div>

            </div>

            <p className={styles.copyright}>
                Copyright © 2021-2025 tomoki.dojo All Rights Reserved.
            </p>
        </footer>
    )
}

export default Footer
